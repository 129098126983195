import React from 'react';
import NoStretchDiv from '../../helpers/NoStretchDiv';

const CenteredItem = (props) => {
    return (
        <NoStretchDiv>
            <div className='center-form'>
                {props.header ? <h2>{props.header}</h2> : null}
                {props.description ? <p className='secondary'>{props.description}</p> : null}
                {props.item}
                {props.children}
            </div>
        </NoStretchDiv>
    );
}

export default CenteredItem;