import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useRouter } from 'next/router'
import storage from 'store/storages/localStorage';
import { message } from 'antd';
import ReactGA from "react-ga";
import useSWR, { SWRConfig } from 'swr'
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'

import PickUsername from '../components/forms/PickUsername';
import CenteredItem from '../components/common/CenteredItem';

import {callApi} from '../helpers/Api';
import UserContext from '../context/UserContext';
import AppContext from '../context/AppContext';

import '../libs/fontawesome-5.7.2/css/solid.min.css';
import 'rc-texty/assets/index.css';
import 'rc-banner-anim/assets/index.css';
import '../components/anim/style/antMotionStyle.scss';

import '../styles/index.scss';
import '../styles/App.scss'; 
import '@fortawesome/fontawesome-svg-core/styles.css';

ReactGA.initialize("UA-161725577-1");
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig()
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      integrations: [
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(distDir, 'app:///_next')
            return frame
          },
        }),
      ],
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
}

const AppHook = ({ Component, pageProps }) => {
    const router = useRouter()
    const handleLogout = () => {
        callApi('/auth/logout', {
            method: 'get',
            withCredentials: true, 
            credentials: 'same-origin',
        })
        .then(()=>{
            storage.write('currentStory', JSON.stringify({}));
            setLoggedInUser(null);
            router.reload();
        })
    }

    const [appLoaded, setAppLoaded] = useState(false);
    const [siderLoaded, setSiderLoaded] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [showPickUsername, setShowPickUsername] = useState(false);
    const { data: notificationCountPoll } = useSWR(loggedInUser ? ('/api/own/notification/count/unread/' + loggedInUser.username) : null, callApi, {
        refreshInterval: 300000,
    });

    const [notificationCount, setNotificationCount] = useState(0);

    const fillCred = useCallback(() => {
        callApi('/auth/verify')
        .then(res => {
            setLoggedInUser(res.user);
            setAppLoaded(true)
        })
        .catch(err => {
            handleLogout();
            setAppLoaded(true)
        });
    }, [])

    const refreshContext = () => {
        fillCred();
    }

    useEffect(() => {
        if(!loggedInUser){
            fillCred();
        } else {
            setAppLoaded(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(loggedInUser && notificationCountPoll)
            setNotificationCount(parseInt(notificationCountPoll[0].count))
    }, [loggedInUser, notificationCountPoll]);

    useEffect(() => {
        if(loggedInUser && loggedInUser.username == null){
            setShowPickUsername(true);
        } else {
            setShowPickUsername(false);
        }
    }, [loggedInUser]);

    useEffect(() => {
        if(appLoaded && process.env.NODE_ENV === 'production') {
            const trackPage = page => {
                ReactGA.set({
                userId: loggedInUser ? loggedInUser.id : null,
                page,
                });
                ReactGA.pageview(page);
            };
            trackPage(router.asPath);
        }
    }, [appLoaded, router.asPath])

    const handleLogin = (username, password, redirect='/') => {
        callApi(`/auth/login`, {
            method: 'POST',
            withCredentials: true, 
            credentials: 'include',
            body: JSON.stringify({
                username,
                password
            })
        })
        .then(res =>{
            if(res.user) {
                setLoggedInUser(res.user);
                router.replace(redirect)
            } else {
                message.error('Username or Password is not correct.');
            }
        })
        .catch(err => {
            console.log(err)
            message.error('We encountered an error. Please try again later.');
        });
    }

    return (
        <div>
            {(showPickUsername) ? (
                <CenteredItem
                    item={
                        <div>
                            <h2>You don't have a username!</h2>
                            <h1>Let's fix that.</h1>
                            <PickUsername 
                                buttonText='I choose this!'
                                handleSubmit={(usernameValue) => {
                                    return callApi('/auth/add_username', {
                                        method: 'PUT',
                                        body: JSON.stringify({
                                            username: usernameValue
                                        })
                                    })
                                    .then(res => {
                                        if(res.user) {
                                            var temp = loggedInUser;
                                            temp.username = usernameValue;
                                            setLoggedInUser(temp);
                                            setShowPickUsername(false);
                                        } else {
                                            message.error('Encountered an error. Try again or let us know what went wrong.')
                                        }
                                    })
                                }}
                            />
                        </div>
                    }
                />
            ) : (
                <AppContext.Provider value={{
                    appLoaded: appLoaded, 
                    siderLoaded: siderLoaded,
                    setSiderLoaded: setSiderLoaded,
                }}>
                <UserContext.Provider value={{
                    loggedInUser: loggedInUser, 
                    notificationCount: notificationCount,
                    setNotificationCount: setNotificationCount,
                    handleLogin: handleLogin,
                    handleLogout: handleLogout,
                    setLoggedInUser: setLoggedInUser,
                    refreshContext: refreshContext,
                }}>
                    <SWRConfig
                        value={{
                            fetcher: callApi,
                        }}
                    >
                        <Component 
                            {...pageProps} 
                        />
                    </SWRConfig>
                </UserContext.Provider>
                </AppContext.Provider>
            )}
        </div>
    );
}

export default AppHook;