import React, { useState } from 'react'
import { Form, Input, Button } from 'antd';
import _ from 'lodash';

import {callApi} from '../../helpers/Api';
import Icon from '../icon/Icon';

const PickUsername = (props) => {
    const [usernameValue, setUsernameValue] = useState('');
    const [usernameExists, setUsernameExists] = useState(true);

    const inputStyle = {marginBottom: '0'}

    const checkUsername = (value) => {
        callApi('/api/user_account/check/' + value)
        .then(res => {
            if(res.length > 0) {
                setUsernameExists(true);
            } else {
                setUsernameExists(false);
            }
        })
        .catch(err => {console.log(err)});
    }

    const debouncedCheckUsername = _.debounce(checkUsername, 700);

    const usernameChange = ({ target: { value } }) => {
        setUsernameValue(value);
        debouncedCheckUsername(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.handleSubmit(usernameValue)
    }

    return (
        <Form layout='vertical'>
            <Form.Item
                name='username'
                style={inputStyle}
                rules={[
                    {
                        required: true,
                        message: 'Username is required!',
                    },
                    {
                        required: true,
                        pattern: new RegExp("^[0-9a-zA-Z-_]+$"),
                        message: 'Must only contains letters, numbers, hypen or underscore.',
                    },
                    {
                        required: true,
                        max: 20,
                        min: 3,
                        message: "Must be between 3 and 20 characters."
                    }
                ]}
            >
                {(usernameValue.length > 0) ? (
                    (usernameExists) ? (
                        <div className="text-darkable">
                            <Icon color='red' name='times' /> Username is not available.
                        </div>
                    ) : (
                        <div className="text-darkable">
                            <Icon color='green' name='check' /> Username is available.
                        </div>
                    )
                ) : null}  
                <Input 
                    placeholder="jdoe2" 
                    type="text"
                    name="username"
                    onChange={usernameChange}
                />
            </Form.Item>
            <Form.Item style={inputStyle} >
                <Button block type="primary" htmlType="submit" onClick={handleSubmit}>{props.buttonText}</Button>
            </Form.Item>
        </Form>
    );
}

export default PickUsername;